
@mixin phone {
  @media only screen and (max-width: 480px){
    @content;
  }
}
@mixin notPhone {
  @media only screen and (min-width: 481px){
    @content;
  }
}

@mixin smartPhone {
	@media only screen and (min-width : 320px) and (max-width : 480px) {
		@content;
	}
}

@mixin smartPhoneLandscape {
	@media only screen and (min-width : 321px) {
		@content;
	}
}


@mixin smartPhonePortrait {
	@media only screen and (max-width : 320px) {
		@content;
	}
}


@mixin tablet{
	@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
		@content;
	}
}


@mixin ipadLandscape{
	@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
		@content;
	}
}


@mixin ipadPortrait{
	@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
		@content;
	}
}


@mixin desktop{
	@media only screen and (min-width : 1224px) {
		@content;
	}
}


@mixin largeScreens{
	@media only screen and (min-width : 1824px) {
		@content;
	}
}


@mixin iphone4{
	@media only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
		@content;
	}
}

