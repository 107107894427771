 .anim-slider{
  height:530px;
  text-align: left;
 }
 .anim-slide div
  {
    color:$white;
    font-size: 36px;
    line-height: 42px;
    background-color: rgba($blue, 0.7);
    padding:5px 15px;
    span{
      color:$white;
      font-size: 36px;
      line-height: 42px;
      position: relative;
      @include sm{
        font-size: 20px;
      }
      @include xs{
        font-size: 18px;
      }
      display: inline-block;
      vertical-align: middle;
      margin-right: 30px;
    }
    a{
      color: $body;
      display: inline-block;
      vertical-align: middle;
      background-color:$white;
      padding:8px 15px;
      margin-left: 20px;
      font-size: 16px;
      position: relative;
      @include sm{
        font-size: 13px;
      }
      @include xs{
        font-size: 12px;
      }
    }
    @include sm{
      font-size: 20px;
    }
    @include xs{
      font-size: 18px;
    }
  }
.anim-slide{
  text-align: left;
}
  .anim-slide h2
  {
    color: $white;
    font-size: 24px;
    font-weight: 200;
  }

  .anim-slide div.title1{
      left:20%; 
      bottom:15%;
        width:30%;

      @include xs{
        right:10%;
        left:10%;
        width:90%;
      }
  }
