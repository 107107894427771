.selectMessage{
	display: inline-block;
	font-size:24px;
	line-height:38px;
	margin-right: 30px;
}

.buttonsContainer{
	border-bottom:1px solid $blue;
	padding-bottom: 15px;
	margin-bottom: 30px;
	a{
		display: inline-block;
		background:$blue;
		color:$white;
		padding:8px 14px;
		margin-right: 25px;
		cursor: pointer;
	}
}
.projectDetailCaption{
	margin-bottom: 30px;
	a{
		color:$body;
	}
	span.name{
		display: block;
		text-align: center;
		border-bottom:1px solid $blue;
		font-size: 18px;
		margin-bottom: 10px;
		margin-top: 5px;
		padding-bottom: 5px;
		color:$body;
	}
	strong{
		font-weight: 400;
		color:$blue;
	}
}
.singleProjectCaption{
	margin-bottom: 30px;
	padding:10px !important;
	min-height:310px;
	a{
		color:$body;
	}
	img{
		width: 90%;
	}
	span.name{
		display: block;
		text-align: center;
		border-bottom:1px solid $blue;
		font-size: 18px;
		margin-bottom: 10px;
		margin-top: 5px;
		padding-bottom: 5px;
		color:$body;
	}
	strong{
		font-weight: 400;
		color:$blue;
	}
}