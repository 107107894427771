/*Parallax */
section.module:last-child {
  margin-bottom: 0;
}
section.module h2 {
  margin-bottom: 40px;
  font-size: 30px;
}
section.module p {
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 200;
}
section.module p:last-child {
  margin-bottom: 0;
}
section.module.content {
  padding: 40px 0;
}
section.module.parallax {
  height: 450px;
  background-size: cover;
  background-position: 50% 0%;
  background-repeat: no-repeat;
  @include lg{
	  background-attachment: fixed;
  }
  @include md{
	  background-attachment: fixed;
  }
  @include sm{
	  background-attachment: fixed;
  height: 300px;
  }
  @include xs{
  background-position: 50% 50%;
	  background-size: cover;
    height:250px;
  }
}
section.module.parallax h1 {
  color: rgba(255, 255, 255, 0.9);
  font-size: 90px;
  @include xs{
    font-size: 30px;
    line-height: 250px;
  }
  @include sm{
    font-size: 50px;
    line-height: 300px;
  }
  line-height: 400px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 0;
  margin-bottom: 0;
}
section.module.parallax-1 {
  background-image: url("../img/slider/1.jpg");
}