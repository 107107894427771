footer{
	background:$footer;
	padding:25px 0;
	.copyright{
		color:$white;
		line-height: 24px;
		@include opensans();
		a{
			@include transition(all, 0.30s, ease-in-out);
			color:$white;
			&:hover{
				color:$blue;
			}
		}
	}

	.footerSubscripitonMessage{
		display: table;
		vertical-align: middle;
		span{
			color:$white;
			font-size: 18px;
			@include sm{
				font-size: 20px;
			}
			@include xs{
				font-size: 18px;
			}
			display: inline-block;
			vertical-align: middle;
			width: 24%;
			@include xs{
				width:100%;
			}
		}
		p{
			display: inline-block;
			vertical-align: middle;
			margin-left: 20px;
			font-size: 14px;
			color:$white;
			width: 70%;
			border-left:1px solid $white;
			padding-left: 20px;
			@include xs{
				width:100%;
				border:none;
				padding-left: 0;
				margin-left: 0;
				margin-top: 10px;
			}
		}
	}

	.subscriptionForm{
		margin-top: 15px;
		input{
			width:100%;
			padding:8px;
			border:none;
			outline:0;
			box-shadow:none;
			font-size:14px;
		}
		input[type="submit"]{
			background:$blue;
			max-width: 50px;
			color:$white;
		}
	}
}