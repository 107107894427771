@import "basics/media";
@import "basics/devices";
@import "basics/colors";
@import "basics/basicMixins";
@import "basics/fonts";
@import "basics/helpers";
@import "header";
@import "footer";
@import "parallax";
@import "news";
@import "projects";
@import "index";
@import "slider";

body{
	@include quicksand();
	color:$body;
	font-weight:400;
	font-size:14px;
	line-height:22px;
}
a{
	&:hover{
		text-decoration: none;
	}
}
ul{
	display: block;
    list-style-type: disc;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0px;
    margin:0;
    padding:0;
}
.col-xs-12{
	@include xs{
		margin-bottom: 30px;
	}
}
.opensans{
	@include opensans();
}

.opensans.light{
	@include opensans();
	font-weight:300;
}
.height30{
	height:30px;
}
.height60{
	height:60px;
}
.section{
	padding:60px 0;
}
.grayBg{
	background: #eaeaea;
}

.buleHeading{
	color:$blue;
	font-size:36px;
	margin-top: 0;
	margin-bottom: 20px;
	font-weight:400;
}
.centeredText{
	text-align: center;
}
.linkButton{
	color: $body;
	display: inline-block;
	background-color:$white;
	padding:8px 15px;
	font-size: 14px;
}
.bodyColor{
	color:$body;
}
.projectCaption{
	margin-top: 15px;
	font-size:16px;
}

.blueBorder{
	border:1px solid $blue;
	padding:10px;
}

.siteBreadcrumb{
	background:#eaeaea;
	padding:14px 0;
	ul{
		list-style-type: none;
		li{
			display: inline-block;
				@include opensans();
			a{
				color:$body;
				display: inline-block;
				&:after{
					content: ">";
					margin-right: 10px;
					padding-left: 10px;
					line-height: 20px;
				}
			}
		}
	}
}

.borderedBlueHeading{
	font-size:24px;
	font-weight:400;
	margin-top: 0;
	margin-bottom: 25px;
	padding-bottom: 10px;
	border-bottom:1px solid $blue;
	color:$blue;
}

.subHeading{
	color:$blue;
	font-size:16px;
	margin-top: 30px;
	margin-bottom: 4px;
	@include opensans();
}

.requestNow{
	background-image: url('../img/services/request_bg.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size:cover;
	padding:30px 0;
	h3{
		font-size: 30px;
		color:$white;
		margin-top: 0;
		margin-bottom: 0;
		line-height: 50px;
		font-weight: 400;
	}
	a{
		font-size: 16px;
		padding:14px 24px;
		background:$white;
		color:$body;
		display: inline-block;
	}
}

.relatedEntity{
	
	text-align:center;
	h5{
		font-weight:400;
		font-size:24px;
		margin-top: 12px;
		margin-bottom: 12px;
		color:$body;
	}
}

.bulletList{
	list-style-type: none;
	margin-top: 35px;
	li{
		display: block;
		margin-bottom: 30px;
		padding-left: 20px;
		background-image: url('../img/icons/blue_dot.png');
		background-repeat: no-repeat;
		background-position: left top 4px;
	}
	
}

aside{
	padding:10px;
	border:1px solid $blue;
	margin-bottom: 30px;
	h4{
		color:$blue;
		border-bottom:1px solid $blue;
		padding-bottom: 5px;
		font-size: 24px;
		margin-top: 5px;
		margin-bottom: 10px;
		font-weight: 400;
		text-align: center;
	}
	.singleSideItem{
		border-bottom: 1px solid #949494;
		margin-bottom: 20px;
		padding-bottom: 10px;
		text-align: center;
		span{
			color:$body;
			display: block;
			margin-top: 10px;
		}
		&:last-child{
			border-bottom:none;
		}
	}
}

.hrAbsoluteCaption{
	position:absolute;
	top: 15%; 
	left:50px;
	right:50px;
	font-size:16px;
	text-align:center;
}

.jobApplication{
	border:1px solid $blue;
	padding:20px;
	margin-top: 30px;
	span{
		font-size: 30px;
		line-height: 38px;
		display: inline-block;
	}
	a{
		display: inline-block;
		background:$blue;
		cursor:pointer;
		color: $white;
		font-size: 16px;
		padding:10px 16px;
		float:right;
	}
	form{
		display: none;
		margin-top: 50px;
	}
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus,textarea:active,
input[type="text"]:active,
input[type="password"]:active,
input[type="datetime"]:active,
input[type="datetime-local"]:active,
input[type="date"]:active,
input[type="month"]:active,
input[type="time"]:active,
input[type="week"]:active,
input[type="number"]:active,
input[type="email"]:active,
input[type="url"]:active,
input[type="search"]:active,
input[type="tel"]:active,
input[type="color"]:active,
select:active,
select:focus,
.uneditable-input:active {   

  box-shadow: none !important;
  outline: 0 none !important;
}

select {
    border: 1px solid #ccc;
    border-radius:0;
   	background: transparent;
   	width: 100%;
   	padding: 10px 35px 10px 10px;
   	font-size: 14px;
   	height: 44px;
   	-webkit-appearance: none;
   	-moz-appearance: none;
   	appearance: none;
    background: url('../img/icons/arrow-down.png') 98% / 2% no-repeat #fff;
} 
select.biggerArrow{
	background: url('../img/icons/arrow-down.png') 96% / 5% no-repeat #fff;
}

option{
	padding:3px;
}

.form-control{
	height:44px;
	padding:11px 12px;
	border-radius:0;
}
.submitBtnContainer{
	text-align:center;
}
.submitBtn{
	display: inline-block;
	background:$blue;
	cursor:pointer;
	color: $white;
	font-size: 16px;
	padding:10px 16px;
	text-align:center;
	border:none;
	outline:0;
	box-shadow:none;
}

#googlemaps{
	height:400px;
	@include xs{
		height:300px;
	}
}

.contactLine{
	margin-bottom: 10px;
	i{
		color:$blue;
		display: inline-block;
		margin-right: 10px;
		width:10px;
		vertical-align: top;
		line-height: 22px;
	}
	address{
		display: inline-block;
		width:90%;
	}
	a{
		color:$blue;
	}
}

#filterContainer .mix{
    display: none;
}
.form-group .borderInput{
	border:1px solid #f2f2f2;
	padding:0 10px 10px;
	label{
		font-size: 12px;
		line-height: 12px;
		color:#9a9a9a;
		font-weight: 400;
	}
	input, textarea, select{
		border:none;
		box-shadow:none;
		padding-left: 0;
	}
}