.newsIndex{
	.newsItem{
		margin-bottom: 30px;
	}
	.newsCaption{
		margin-left: 40px;
		@include xs{
			margin-left: 15px;
			margin-right: 15px;
		}
		h2{
			color:$blue;
			font-size: 24px;
			font-weight: 400;
		}
		a{
			margin-top: 10px;
			margin-right: 5px;
			display: inline-block;
			float:right;
			background: $blue;
			color:$white;
			padding:8px 15px;
			font-size: 14px;
		}		
	}
}

.newsDetail{
	.newsDescription{
		margin-top: 15px;
	}
}

.newsGallery{
	margin-top: 50px;
	img{
		margin-bottom: 30px;
	}
}