.navbar-default{
	background:$white;
	margin-bottom: 0;
	.container{
		@include sm{
			width:95%;
		}
	}
}
.navbar-toggle{
	margin-top: 20px;
	border:none;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus{
	background:$white;
}
.navbar-brand{
	height:auto;
	padding:10px;
	@include sm{
		width:120px;
	}
	@include xs{
		width: 150px;
	}
}

.navbar-nav>li>a{
	padding-top: 40px;
	padding-bottom: 40px;
	font-weight:500;
	font-size:15px;
	@include sm{
		font-size: 13px;
		padding-top: 25px;
		padding-bottom: 25px;
		padding-right: 7px;
		padding-left: 7px;
	}
	@include xs{
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:hover, .navbar-default .navbar-nav>.open>a:focus{
	background:$footer;
	color:$white;
}
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus{
	background:$blue;
	color:$white;
}

.dropdown-menu{
	z-index: 1010;
	li{
		a{
			padding:10px;
		}
	}
}