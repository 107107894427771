.homeSlider{
	.homeSliderCaption{
		background-color: rgba($blue, 0.7);
		padding: 5px 25px;
		position:absolute;
		bottom: 50px; 
		@include sm{
			bottom:15px;
		}
		@include xs{
			bottom:5px;
		}
		left:0;
		display: table;
		vertical-align: middle;
		span{
			color:$white;
			font-size: 36px;
			line-height: 42px;
			@include sm{
				font-size: 20px;
			}
			@include xs{
				font-size: 18px;
			}
			display: inline-block;
			vertical-align: middle;
			margin-right: 30px;
		}
		a{
			color: $body;
			display: inline-block;
			vertical-align: middle;
			background-color:$white;
			padding:8px 15px;
			margin-left: 20px;
			font-size: 16px;
			@include sm{
				font-size: 13px;
			}
			@include xs{
				font-size: 12px;
			}
		}
	}
}


.homeFeaturette{
	text-align: center;
	a{
		color:$body;
	}
	h4{
		font-size: 24px;
		font-weight: 400;
	}
}

.newsList{
	list-style-type: none;
	margin-top: 35px;
	li{
		display: block;
		border-bottom:1px solid $blue;
		margin-bottom: 10px;
	}
	h4{
		font-weight: 400;
		font-size: 18px;
		margin-top: 0;
		margin-bottom: 5px;
		padding-left: 20px;
		background-image: url('../img/icons/blue_dot.png');
		background-repeat: no-repeat;
		background-position: left center;
	}
	p{
		margin-left: 60px;
	}
}